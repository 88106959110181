import { IProductDimensionsWithAvailabilitiesFull, ProductDimensionFull } from '@msdyn365-commerce/commerce-entities';

import { ProductsDataActions, ProductDimension, SimpleProduct } from '@msdyn365-commerce/retail-proxy';

import { ArrayExtensions } from '@msdyn365-commerce-modules/retail-actions';

import { createInventoryAvailabilitySearchCriteria } from '@msdyn365-commerce-modules/retail-actions/';

import { ProductDimensionAvailabilitySearchCriteria } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import { ICCartProps } from '../../modules/c-cart/c-cart.props.autogenerated';
import { ICCartData } from '../../modules/c-cart/c-cart.data';
import { ICartLineProps } from '../../themes/peruvian/views/components/cartlineitem.component';

export interface IDimensionForSelectedVariant extends ProductDimensionFull, IProductDimensionsWithAvailabilitiesFull {}

export interface IExtendedSimpleProduct extends SimpleProduct {
    productDimension?: IDimensionForSelectedVariant[];
    NB?: boolean;
}

export async function getProductsWithNBFlag(props: ICCartProps<ICCartData>, products: SimpleProduct[]) {
    const asyncResult = Promise.all(
        products.map(async product => {
            const result = await __getBackorderableFlagOnSelectedDimension(props, product);
            if (result) {
                return {
                    ...product,
                    productDimension: result
                };
            } else {
                return {
                    ...product,
                    productDimension: []
                };
            }
        })
    );
    return asyncResult;
}

export async function __getBackorderableFlagOnSelectedDimension(
    props: ICCartProps<ICCartData> | ICartLineProps,
    product: SimpleProduct
): Promise<IDimensionForSelectedVariant[]> {
    if (!ArrayExtensions.hasElements(product.Dimensions)) {
        return [];
    }

    const MatchingDimensionValues: ProductDimension[] = [];
    product.Dimensions.forEach(dimension => {
        MatchingDimensionValues.push({
            DimensionTypeValue: dimension.DimensionTypeValue,
            DimensionValue: dimension.DimensionValue
        });
    });
    const promises = product.Dimensions.map(dimension => {
        const shippingInventoryConfiguration = createInventoryAvailabilitySearchCriteria(
            props.context.actionContext,
            [],
            true,
            undefined,
            undefined
        );
        const searchCriteria: ProductDimensionAvailabilitySearchCriteria = {
            RequestedDimensionTypeValue: dimension.DimensionTypeValue,
            MatchingDimensionValues: MatchingDimensionValues.filter(item => item.DimensionTypeValue !== dimension.DimensionTypeValue),
            DefaultWarehouseOnly: shippingInventoryConfiguration.DefaultWarehouseOnly,
            FilterByChannelFulfillmentGroup: shippingInventoryConfiguration.FilterByChannelFulfillmentGroup,
            DeliveryModeTypeFilterValue: shippingInventoryConfiguration.DeliveryModeTypeFilterValue
        };

        const dimensionValuesPromise = ProductsDataActions.getDimensionValuesWithEstimatedAvailabilitiesAsync(
            { callerContext: props.context.actionContext, queryResultSettings: {} },
            product.MasterProductId ? product.MasterProductId : product.RecordId,
            searchCriteria
        );
        const fullDimensionPromise = dimensionValuesPromise.then(dimensionValues => {
            const fullDimension: IProductDimensionsWithAvailabilitiesFull = {
                ...dimension,
                dimensionValuesWithInventory: dimensionValues
            };
            return fullDimension;
        });

        return fullDimensionPromise;
    });

    const dimensionValuesResults = await Promise.all(promises);

    return prepareDimensionsDataResult(dimensionValuesResults);
}

const prepareDimensionsDataResult = (dimensions: IProductDimensionsWithAvailabilitiesFull[]): IDimensionForSelectedVariant[] => {
    return dimensions.map(newDimension => {
        const oldDimension: IDimensionForSelectedVariant = { ...newDimension };
        oldDimension.DimensionValues = ArrayExtensions.validValues(
            newDimension.dimensionValuesWithInventory?.map(value => value.DimensionValue)
        );
        return oldDimension;
    });
};
